import { ApiError } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest, queryClient } from '@share/helpers'
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = yup
  .array(
    yup.object({
      id: yup.string().defined(),
      notes: yup.string().defined(),
      createdAt: yup.string().defined(),
      admin: yup.object({
        id: yup.string().defined(),
        name: yup.string().defined(),
        avatar: yup.string().defined().nullable(),
      }),
    }),
  )
  .defined()

export type GetVRFQNotesRequest = { orderId: string }
export type GetVRFQNotesResponse = yup.InferType<typeof responseSchema>

const getVRFQNotesRequest = ({ orderId }: GetVRFQNotesRequest) =>
  apiRequest<GetVRFQNotesResponse>({
    url: API_LIST.vrf.VRFQNotes.root(orderId),
    responseSchema,
  })

export const useGetVRFQNotesRequest = ({ orderId }: GetVRFQNotesRequest) =>
  useQuery<GetVRFQNotesResponse, ApiError>({
    keepPreviousData: true,
    queryKey: [API_TAGS.vrf.VRFQNotes],
    queryFn: () => getVRFQNotesRequest({ orderId }),
  })

export const invalidateVRFQNotes = () => queryClient.invalidateQueries([API_TAGS.vrf.VRFQNotes])
