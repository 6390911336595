import * as yup from 'yup'

import { productCategoryModelSchema } from '../product'

import { RFQVendorResponseSchema } from './rfq-vendor-response.schema'

export const vendorResponseFormOrderProductSchema = yup.object({
  category: productCategoryModelSchema.omit(['code']).defined(),
  id: yup.string().defined(),
  image: yup.string().defined(),
  mpn: yup.string().defined(),
  name: yup.string().defined(),
  subcategory: yup.string().defined(),
  vendorResponse: yup.array().of(RFQVendorResponseSchema).defined(),
})

export const vendorResponseFormOrderProductsSchema = yup
  .array()
  .of(vendorResponseFormOrderProductSchema.defined())
  .defined()

export type VendorResponseFormOrderProductModel = yup.InferType<
  typeof vendorResponseFormOrderProductSchema
>
export type VendorResponseFormOrderProductsModel = yup.InferType<
  typeof vendorResponseFormOrderProductsSchema
>
