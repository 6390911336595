import * as yup from 'yup'

export const chartOrderAtRiskModelSchema = yup.object().shape({
  name: yup.string().defined(),
  filter: yup.string().defined(),
  value: yup.number().defined(),
  fill: yup.string().defined(),
})

export const chartOrdersAtRiskModelSchema = yup.array().of(chartOrderAtRiskModelSchema).required()

export type ChartOrderAtRiskModel = yup.InferType<typeof chartOrderAtRiskModelSchema>
export type ChartOrdersAtRiskModel = yup.InferType<typeof chartOrdersAtRiskModelSchema>
