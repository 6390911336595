import { ApiError } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest, queryClient } from '@share/helpers'
import { productModelSchema } from '@share/schemas'
import { useQuery } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = yup.object({
  product: productModelSchema.defined(),
  alternativeProduct: yup
    .object({
      dotsInformation: productModelSchema.defined(),
    })
    .nullable(),
})

type GetVRFProductsByIdRequestParams = {
  vrfFormId: string
  vrfFormProductId: string
}

export type GetVRFProductsResponse = yup.InferType<typeof responseSchema>

const getVRFProductsByIdRequest = async ({
  vrfFormId,
  vrfFormProductId,
}: GetVRFProductsByIdRequestParams) =>
  apiRequest<GetVRFProductsResponse>({
    method: 'GET',
    url: API_LIST.vrf.compareProductsByIds(vrfFormId, vrfFormProductId),
    responseSchema,
  })

export const useGetVRFProductsByIdRequest = (params: GetVRFProductsByIdRequestParams) =>
  useQuery<GetVRFProductsResponse, ApiError>({
    queryKey: [API_TAGS.vrf.vrfFormProduct, params],
    queryFn: () => getVRFProductsByIdRequest(params),
  })

export const invalidateVRFProductsById = (params: GetVRFProductsByIdRequestParams) =>
  queryClient.invalidateQueries([API_TAGS.vrf.vrfFormProduct, params])
