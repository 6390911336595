import { Outlet } from 'react-router-dom'
import { selectUser, useUserStore } from '@features/auth'
import { useSupportController } from '@features/support/model/controller'
import { Header } from '@widgets/header'
import { Sidebar } from '@widgets/sidebar'
import { SiteActionMenu } from '@widgets/site-action-menu'

import '@app/modals/register'

import s from './main-layout.module.scss'

export const MainLayout = () => {
  const adminStore = useUserStore(selectUser)

  useSupportController({
    user: adminStore,
  })
  return (
    <div className={s.root}>
      <Sidebar />

      <div className={s.content}>
        <Header siteActionMenuSlot={<SiteActionMenu />} />

        <main className={s.main}>
          <div className={s.inner}>
            <div className={s.scroll}>
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
