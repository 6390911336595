import { register } from '@ebay/nice-modal-react'
import {
  SELECTED_DELIVERY_ASSETS_DIALOG_ID,
  SelectedDeliveryAssetsDialog,
} from '@entities/delivery/delivery-assets-selection'
import { ORDER_DISPUTE_SUCCESS_ALERT_ID, OrderDisputeSuccessAlert } from '@entities/order'
import {
  ORDER_CANCEL_MODAL_ID as GENERAL_ORDER_CANCEL_MODAL_ID,
  ORDER_NAME_EDIT_DIALOG_ID,
  ORDER_NOTES_EDIT_DIALOG_ID,
  OrderCancelDialog as GeneralOrderCancelDialog,
  OrderNameEditDialog,
  OrderNotesEditDialog,
} from '@entities/order/common'
import { ORDER_DISPUTE_MODAL_ID } from '@entities/order/common/order-dispute'
import { OrderDisputeDialog } from '@entities/order/common/order-dispute/ui/organisms'
import {
  ORDER_PO_NUMBER_EDIT_DIALOG_ID,
  OrderPONumberEditDialog,
} from '@entities/order/order-po-number'
import { ConfirmEvent, DeleteEvent } from '@entities/services-modals'
import {
  VIEW_CATALOG_PRODUCT_MODAL_ID,
  ViewCatalogProductModal,
} from '@features/catalog/view-catalog-product'
import {
  ORDER_ACTUAL_ETA_UPDATE_DIALOG_ID,
  UpdateOrderActualEtaDialog,
} from '@features/order/common'
import { ORDER_CANCEL_MODAL_ID, OrderCancelDialog } from '@features/order/order-cancel'
import { CONFIRM_EVENT_MODAL_ID, DELETE_EVENT_MODAL_ID } from '@share/constants'

// Services modals
register(CONFIRM_EVENT_MODAL_ID, ConfirmEvent)
register(DELETE_EVENT_MODAL_ID, DeleteEvent)

// TODO: refactor as on platform
// General Order
register(GENERAL_ORDER_CANCEL_MODAL_ID, GeneralOrderCancelDialog)

// Order
register(ORDER_NOTES_EDIT_DIALOG_ID, OrderNotesEditDialog)
register(ORDER_DISPUTE_MODAL_ID, OrderDisputeDialog)
register(ORDER_CANCEL_MODAL_ID, OrderCancelDialog)
register(ORDER_DISPUTE_SUCCESS_ALERT_ID, OrderDisputeSuccessAlert)
register(ORDER_NAME_EDIT_DIALOG_ID, OrderNameEditDialog)
register(ORDER_PO_NUMBER_EDIT_DIALOG_ID, OrderPONumberEditDialog)
register(ORDER_ACTUAL_ETA_UPDATE_DIALOG_ID, UpdateOrderActualEtaDialog)

// Product
register(VIEW_CATALOG_PRODUCT_MODAL_ID, ViewCatalogProductModal)

register(SELECTED_DELIVERY_ASSETS_DIALOG_ID, SelectedDeliveryAssetsDialog)
