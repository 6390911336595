import * as yup from 'yup'

const possiblePurchasePriorityValues = ['PRICE', 'DELIVERY_DATE', 'ASSET_SPECS'] as const
export type PurchasePriorityValue = (typeof possiblePurchasePriorityValues)[number]

export const purchasePriority = yup.object({
  priority: yup.number().defined(),
  value: yup.mixed<PurchasePriorityValue>().oneOf(possiblePurchasePriorityValues).defined(),
})

export const purchasePriorities = yup.array(purchasePriority.defined()).defined()
