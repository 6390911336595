import { IceCatParsedProduct, Undefinable } from '@share/@types'
import { AssetSpecDetailsModel, ProductModel, ProductMutationRequest } from '@share/schemas'
import { emptyStringToNull, undefToEmptyString } from '@share/utils'
import * as yup from 'yup'

import { ProductAdditionalInfoFormValues, ProductMutationFormValues } from './types'

export const DEFAULT_PRODUCT_MUTATION_FORM_VALUES: ProductMutationFormValues = {
  name: '',
  model: '',
  image: '',
  productCode: '',
  brandId: '',
  subcategoryId: '',
  features: [{ feature: '' }],
  specs: [],
  source: '',
}

export const productMutationValidationSchema = yup.object().shape({
  name: yup.string().label('Name').required(),
  model: yup.string().label('Model').required(),
  image: yup.string().label('Image').transform(emptyStringToNull).notRequired(),
  productCode: yup.string().label('MPN').required(),
  brandId: yup.string().label('Brand').required(),
  subcategoryId: yup.string().label('Subcategory').required(),
  features: yup
    .array()
    .of(yup.object().shape({ feature: yup.string() }))
    .notRequired(),
  specs: yup.array().of(
    yup
      .object()
      .shape({
        specDetailId: yup.string(),
        specDetailValue: yup.string(),
      })
      .notRequired(),
  ),
})

export const adaptProductMutationFormValuesToRequest = ({
  features,
  specs,
  source,
  ...formValues
}: ProductMutationFormValues): ProductMutationRequest => {
  const adaptedFeatures = features
    .filter(({ feature }) => feature.length > 0)
    .map(({ feature }) => feature)

  const adaptedSpecs = specs?.map((spec) => ({
    specDetailId: spec.id,
    specDetailValue: spec.value,
  }))

  return {
    ...formValues,
    features: adaptedFeatures?.length ? adaptedFeatures : [],
    specs: adaptedSpecs?.length ? adaptedSpecs : [],
  }
}

export const adaptProductSpecsToFormValues = (specs: ProductModel['specs']) => {
  const result: AssetSpecDetailsModel = []

  specs?.forEach(({ details }) =>
    details.forEach(({ value, ...detail }) => {
      result.push({ ...detail, value: undefToEmptyString(value) })
    }),
  )

  return result
}

export const adaptProductFeaturesToFormValues = (
  features: ProductModel['features'],
): ProductMutationFormValues['features'] => features.map((feature) => ({ feature }))

export const adaptProductToMutationFormValues = (
  product: ProductModel,
): ProductMutationFormValues => ({
  name: product.name,
  model: undefToEmptyString(product.model),
  productCode: undefToEmptyString(product.productCode),
  image: product.image,
  brandId: product.brand.id,
  subcategoryId: product.subcategory.id,
  features: adaptProductFeaturesToFormValues(product.features),
  specs: adaptProductSpecsToFormValues(product.specs),
  source: product.source,
})

export const adaptIceCatProductToMutationFormValues = (
  product: Undefinable<IceCatParsedProduct>,
): ProductMutationFormValues => ({
  image: undefToEmptyString(product?.parsed.image),
  model: undefToEmptyString(product?.parsed.productCode),
  name: undefToEmptyString(product?.parsed.name),
  productCode: undefToEmptyString(product?.parsed.productCode),
  brandId: undefToEmptyString(product?.parsed.brandId),
  subcategoryId: undefToEmptyString(product?.parsed.subcategoryId),
  specs: adaptProductSpecsToFormValues(product?.parsed.specs ?? []),
  features: adaptProductFeaturesToFormValues(product?.description ?? []),
  source: undefToEmptyString(product?.parsed.source),
})

export const productAdditionalInfoFormValidationSchema = yup.object().shape({
  powerSupplyType: yup
    .string()
    .label('Power supply type')
    .when('isPowerOutletNeeded', {
      is: true,
      then: (schema) => schema.required('Choose one of power layout'),
      otherwise: (schema) => schema.notRequired(),
    })
    .transform(emptyStringToNull),
  keyboardLanguage: yup
    .string()
    .label('Keyboard layout type')
    .when('isKeyboardLayoutNeeded', {
      is: true,
      then: (schema) => schema.required('Choose one of keyboard layout'),
      otherwise: (schema) => schema.notRequired(),
    })
    .transform(emptyStringToNull),
  warrantyId: yup
    .string()
    .label('Warranty')
    .when('isWarrantyNeeded', {
      is: true,
      then: (schema) => schema.required('Choose one of warranty'),
      otherwise: (schema) => schema.notRequired(),
    })
    .transform(emptyStringToNull),
  enrollmentId: yup
    .string()
    .label('Enrolment')
    .when('isEnrollmentNeeded', {
      is: true,
      then: (schema) => schema.required('Choose one of MDM/DEP enrolment'),
      otherwise: (schema) => schema.notRequired(),
    })
    .transform(emptyStringToNull),
  installationId: yup
    .string()
    .label('Installation')
    .when('isInstallationNeeded', {
      is: true,
      then: (schema) => schema.required('Choose one of options'),
      otherwise: (schema) => schema.notRequired(),
    })
    .transform(emptyStringToNull),
})

export const adaptProductToAdditionalInfoFormValues = (
  product?: ProductModel,
): Undefinable<ProductAdditionalInfoFormValues> => {
  if (!product) return undefined

  return {
    isPowerOutletNeeded: product.isPowerOutletNeeded,
    isKeyboardLayoutNeeded: product.isKeyboardLayoutNeeded,
    isWarrantyNeeded: product.additionalServices.some((service) => service.type === 'Warranty'),
    isEnrollmentNeeded: product.additionalServices.some((service) => service.type === 'Enrollment'),
    isInstallationNeeded: product.additionalServices.some(
      (service) => service.type === 'Installation',
    ),
    warrantyId: '',
    enrollmentId: '',
    installationId: '',
  }
}
