/* eslint-disable simple-import-sort/imports */
import 'sanitize.css/sanitize.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import '@share/styles/chrome-bug.css'
import '@share/styles/document.scss'
import '@dots/uikit/styles'

import '@assets/locales/i18n'

import ReactDOM from 'react-dom/client'
import FontFaceObserver from 'fontfaceobserver'

import './app/services/sentry'
import App from './app/app'

const openSansObserver = new FontFaceObserver('Satoshi', {})

openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded')
})

const MOUNT_NODE = document.getElementById('root') as HTMLElement

interface IProps {
  Component: typeof App
}
const ConnectedApp = ({ Component }: IProps) => <Component />

const render = (Component: typeof App) => {
  const root = ReactDOM.createRoot(MOUNT_NODE)
  root.render(<ConnectedApp Component={Component} />)
}

render(App)
