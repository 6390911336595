import * as yup from 'yup'

import { orderSubtypeSchema, orderTypeSchema } from '../order'

import { RFQPrioritySchema } from './rfq-priority.schema'
import { VRFStatusesSchema } from './vrf-statuses.schema'

export const RFQGeneralOrderInfoSchema = yup.object({
  client: yup
    .object({
      clientId: yup.string().defined(),
      clientName: yup.string().defined(),
    })
    .defined(),
  customName: yup.string().defined().nullable(),
  daysPassed: yup.number().defined(),
  deliveryCountryInfo: yup
    .object({
      id: yup.string().defined(),
      name: yup.string().defined(),
      flag: yup.string().defined().nullable(),
    })
    .defined(),
  formSubmissionDate: yup.string().defined(),
  notes: yup.number().defined(),
  orderId: yup.string().defined(),
  orderName: yup.string().defined(),
  priority: yup.array(RFQPrioritySchema).defined(),
  requestedEta: yup
    .object({
      from: yup.string().defined(),
      to: yup.string().defined(),
    })
    .defined()
    .nullable(),
  subType: orderSubtypeSchema.defined(),
  type: orderTypeSchema.defined(),
  vendorsForms: yup
    .object({
      total: yup.number().defined(),
      filled: yup.number().defined(),
    })
    .defined(),
  vrfsStatus: VRFStatusesSchema.defined(),
  winnerInfo: yup
    .object({
      formId: yup.string().defined().nullable(),
      orderHasWinner: yup.boolean().defined(),
      vendorCompanyName: yup.string().defined().nullable(),
      vendorId: yup.string().defined().nullable(),
      vendorName: yup.string().defined().nullable(),
    })
    .defined(),
})

export type RFQGeneralOrderInfoModel = yup.InferType<typeof RFQGeneralOrderInfoSchema>
