import * as yup from 'yup'

import { OrderStatus, possibleOrderStatus } from './order-statuses.schemas'

export const commercialOfferPreviewModelSchema = yup
  .object({
    id: yup.string().defined(),
    status: yup.mixed<OrderStatus>().oneOf(possibleOrderStatus).defined(),
    price: yup.string().defined(),
    eta: yup.tuple([yup.string().defined(), yup.string().optional()]).defined(),
    pqLink: yup.string().defined(),
    currencySymbol: yup.string().defined(),
    createdAt: yup.string().defined(),
  })
  .noUnknown()

export const commercialOfferPreviewsModelSchema = yup.object({
  commercialOffers: yup.array(commercialOfferPreviewModelSchema.defined()).defined(),
})

export type CommercialOfferPreviewModel = yup.InferType<typeof commercialOfferPreviewModelSchema>
