import * as yup from 'yup'

import { paginationResponseSchema } from '../core.schemas'

export const adminSchema = yup.object({
  id: yup.string().defined(),
  email: yup.string().defined(),
  phone: yup.string().optional(),
  fullName: yup.string().defined(),
  avatar: yup.string().defined().nullable(),
  companyId: yup.string().defined().nullable(),
  accessLevel: yup.string().optional(),
  createdAt: yup.date().defined(),
  updatedAt: yup.date().defined(),
  deletedAt: yup.date().optional().nullable(),
  ableToUpdateCommercialOffer: yup.boolean().defined(),
})

export const adminPaginationSchema = paginationResponseSchema(adminSchema)

export type AdminModel = yup.InferType<typeof adminSchema>

export const possibleEmailSettings = [
  'DO_NOT_RECEIVE_EMAILS',
  'RECEIVE_EMAILS_FOR_MY_ORDERS',
  'RECEIVE_EMAILS_FOR_ALL_ORDERS',
] as const

export type EmailSettings = (typeof possibleEmailSettings)[number]

export const possibleEmailSettingsSchema = yup.mixed<EmailSettings>().oneOf(possibleEmailSettings)
