import { ApiError } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest, queryClient } from '@share/helpers'
import {
  RFQGeneralOrderInfoSchema,
  vendorResponseFormOrderProductsSchema,
  vendorsRFQGeneralInfoSchema,
} from '@share/schemas'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = yup.object({
  generalOrderInfo: RFQGeneralOrderInfoSchema.defined(),
  vendorsVrfGeneralInfo: yup.array().of(vendorsRFQGeneralInfoSchema.defined()).defined(),
  vendorResponseFormOrderProducts: vendorResponseFormOrderProductsSchema,
})

type GetVRFByIdRequestParams = {
  id: string
}

type GetVRFResponse = yup.InferType<typeof responseSchema>

type GetVRFByIdRequest = {
  params: GetVRFByIdRequestParams
  options?: UseQueryOptions<GetVRFResponse, ApiError>
}

const getVRFByIdRequest = async ({ id }: GetVRFByIdRequestParams) =>
  apiRequest<GetVRFResponse>({
    method: 'GET',
    url: API_LIST.vrf.id(id),
    responseSchema,
  })

export const useGetVRFByIdRequest = ({ params, options }: GetVRFByIdRequest) =>
  useQuery<GetVRFResponse, ApiError>({
    queryKey: [API_TAGS.vrf.root, params],
    queryFn: () => getVRFByIdRequest(params),
    select: ({ generalOrderInfo, vendorResponseFormOrderProducts, vendorsVrfGeneralInfo }) => ({
      generalOrderInfo,
      vendorsVrfGeneralInfo,
      vendorResponseFormOrderProducts: vendorResponseFormOrderProducts.map((product) => ({
        ...product,
        vendorResponse: product.vendorResponse.map((response) => ({
          ...response,
          alternativeId: response.alternativeId,
          condition: response.condition,
          eta: response.eta,
          id: response.id,
          keyboardLayout: response.keyboardLayout,
          mpn: response.mpn,
          notes: response.notes,
          prices: response.prices,
          powerLayout: response.powerLayout,
          quantity: response.quantity,
          warranty: response.warranty,
        })),
      })),
    }),
    ...options,
  })

export const invalidateVRFById = (params: GetVRFByIdRequestParams) =>
  queryClient.invalidateQueries([API_TAGS.vrf.root, params])
