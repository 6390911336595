import { array, InferType, number, object, string } from 'yup'

import { currencyModelSchema } from '../currency'
import { orderDiscountModelSchema } from '../order'

export const deliveryOrderCommercialOfferModelSchema = object().shape({
  id: string().defined(),
  orderId: string().defined(),
  etaMaxDays: number().defined(),
  etaMinDays: number().defined(),
  tax: number().defined().nullable(),
  fees: number().defined().nullable(),
  shippingPrice: number().defined().nullable(),
  shipmentCost: number().defined().nullable(),
  vendorId: string().defined().nullable(),
  validUntil: string().defined(),
  subtotalPrice: number().defined().nullable(),
  totalPrice: number().defined().nullable(),
  pickupDateFrom: string().defined().nullable(),
  pickupDateTo: string().defined().nullable(),
  createdAt: string().optional(),
  updatedAt: string().optional(),
  currency: currencyModelSchema.optional(),
  priceWithoutTaxes: number().defined().nullable(),
  vendor: object({
    companyName: string().defined(),
  })
    .defined()
    .nullable(),
  discount: orderDiscountModelSchema.defined().nullable(),
})

export const deliveryOrderCommercialOffersModelSchema = array().of(
  deliveryOrderCommercialOfferModelSchema,
)

export type DeliveryOrderCommercialOfferModel = InferType<
  typeof deliveryOrderCommercialOfferModelSchema
>
export type DeliveryOrderCommercialOffersModel = InferType<
  typeof deliveryOrderCommercialOffersModelSchema
>
