export const ROUTES = Object.freeze({
  root: '/',
  login: '/login',
  signUp: '/signup',
  googleLogin: '/google-login',

  assets: '/assets',
  assetCreate: '/assets/create',

  employees: '/employees',

  locations: '/locations',

  ORDERS: {
    root: '/orders',
    purchase: {
      editDelivery: 'edit/delivery',
      editShipping: 'edit/shipping',
      editPayment: 'edit/payment',

      createCommercialOffer: '/orders/purchase/:id/commercial-offer/create',
      editCommercialOffer: '/orders/purchase/:id/commercial-offer/edit',
      offerAlternative:
        '/orders/purchase/alternative/:orderProductId/offer/:commercialOfferProductId',
      offerAlternativeManually:
        '/orders/purchase/alternative/:orderProductId/offer-manually/:commercialOfferProductId',

      mutateVendorProductDetails: '/orders/purchase/:orderId/vendor-product-details/:id/mutate',
    },
    delivery: {
      editPickup: 'edit/pickup',
      editDelivery: 'edit/delivery',
      editShipping: 'edit/shipping',
      editInvoice: 'edit/invoice',
      editPayment: 'edit/payment',
      editBoxes: 'edit/boxes',
      editAssets: 'edit/assets',
      editPacking: 'edit/packing',
      createCommercialOffer: 'create/commercial-offer',
      editCommercialOffer: 'edit/commercial-offer',
    },
  },

  slaConfig: {
    risk: '/risk-config',
    riskChart: '/risk-chart',
  },

  vendors: '/vendors',
  vendorCreate: '/vendors/create',
  vendorEdit: '/vendors/:id/edit',

  companies: '/companies',
  companiesAdmins: '/companies-admins',
  catalog: '/catalog',
  catalogProductCreateManually: '/catalog/create-product-manually',
  catalogProductEditManually: '/catalog/product/:id/edit',

  categories: {
    root: '/categories',
  },
  subcategories: {
    root: '/subcategories',
  },
  brands: {
    root: '/brands',
  },
  updates: {
    root: '/updates',
  },
  reports: {
    root: '/reports',
  },

  RFQs: {
    root: '/rfqs',
    view: '/rfqs/:id',
  },
  countries: {
    root: '/countries',
    edit: '/countries/:id/edit',
  },
})

export const NAVIGATION_ROUTES = Object.freeze({
  orders: {
    root: ROUTES.ORDERS.root,
    purchase: {
      toOrderById: (id: string) => `${ROUTES.ORDERS.root}/purchase/${id}`,
      toAlternativeProductFlow: (orderProductId: string, commercialOfferProductId: string) =>
        `${ROUTES.ORDERS.root}/purchase/alternative/${orderProductId}/offer/${commercialOfferProductId}`,
      toManuallyAlternativeProductFlow: (
        orderProductId: string,
        commercialOfferProductId: string,
      ) =>
        `${ROUTES.ORDERS.root}/purchase/alternative/${orderProductId}/offer-manually/${commercialOfferProductId}`,
      toCommercialOffer: (orderId: string, action: string) =>
        `${ROUTES.ORDERS.root}/purchase/${orderId}/commercial-offer/${action}`,
      toCommercialOfferCreate: (orderId: string) =>
        `${ROUTES.ORDERS.root}/purchase/${orderId}/commercial-offer/create`,
      toCommercialOfferEdit: (orderId: string) =>
        `${ROUTES.ORDERS.root}/purchase/${orderId}/commercial-offer/edit`,
      toVendorProductDetailsMutate: (orderId: string, id: string) =>
        `${ROUTES.ORDERS.root}/purchase/${orderId}/vendor-product-details/${id}/mutate`,
    },
    delivery: {
      toOrderById: (id: string) => `${ROUTES.ORDERS.root}/delivery/${id}`,
      toCommercialOfferCreate: (orderId: string) =>
        `${ROUTES.ORDERS.root}/delivery/${orderId}/create/commercial-offer`,
      toCommercialOfferEdit: (orderId: string) =>
        `${ROUTES.ORDERS.root}/delivery/${orderId}/edit/commercial-offer`,
    },
  },

  RFQs: {
    root: '/rfqs',
    slug: (id: string) => `/rfqs/${id}`,
  },

  countries: {
    root: '/countries',
    slug: (id: string) => `/countries/${id}`,
    edit: (id: string) => `/countries/${id}/edit`,
  },
})
