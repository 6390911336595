import { API_LIST } from '@share/constants'
import { post } from '@share/helpers'
import { AdminModel } from '@share/schemas'

interface ILogoutResponse {
  message: string
}

export const logoutRequest = () => post<ILogoutResponse>(API_LIST.company.auth.logout)

export interface SendLogin {
  code: string
  authorizationType: 'GOOGLE' | 'MICROSOFT'
  redirectUrl?: string
}

export interface SendSuperAdminLogin {
  companyId: string
}

export interface LoginResponseSuccess {
  step: 'DASHBOARD' | 'REGISTRATION' | 'SUPER_ADMIN'
  user: AdminModel
}

export interface GoogleCredential {
  credential: string
}

export const sendLoginRequest = async (params: SendLogin) =>
  post<LoginResponseSuccess>(API_LIST.company.auth.login, params)

export const sendSuperAdminLoginRequest = async (params: SendSuperAdminLogin) =>
  post<LoginResponseSuccess>(API_LIST.company.auth.superAdmin, params)
