import * as yup from 'yup'

import { currencyModelSchema } from '../currency'

export const orderCancelSchema = yup.object({
  reason: yup.string().nullable().defined(),
  targetPrice: yup.number().defined(),
  currency: currencyModelSchema.nullable().defined(),
  reasonDescription: yup.string().nullable().defined(),
  reasonLink: yup.string().nullable().defined(),
})

export type OrderCancelModel = yup.InferType<typeof orderCancelSchema>
