import * as yup from 'yup'

import { possibleEmailSettingsSchema } from './admin.schemas'

export const superAdminSchema = yup.object({
  id: yup.string().defined(),
  email: yup.string().defined(),
  phone: yup.string().defined(),
  fullName: yup.string().defined(),
  avatar: yup.string().defined().nullable(),
  createdAt: yup.string().defined(),
  updatedAt: yup.string().defined(),
  emailSettings: possibleEmailSettingsSchema.defined(),
  companies: yup.array().defined().nullable(),
  isSupportAdmin: yup.boolean().defined(),
  isSalesAdmin: yup.boolean().defined(),
})

export type SuperAdminModel = yup.InferType<typeof superAdminSchema>
