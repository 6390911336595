const baseURL = import.meta.env.VITE_APP_API_URL
export const STATIC_URL = import.meta.env.VITE_APP_STATIC_URL
export const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID
export const MICROSOFT_REDIRECT_URI = import.meta.env.VITE_MICROSOFT_REDIRECT_URI
export const SENTRY_ENV = import.meta.env.VITE_SENTRY_ENV
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
export const SUPPORT_KEY = import.meta.env.VITE_SUPPORT_KEY

const headers = {
  'Access-Control-Allow-Credentials': true,
  'Content-Type': 'application/json',
}

export const API_CONFIG = {
  baseURL,
  headers,
  withCredentials: true,
}
