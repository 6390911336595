import * as yup from 'yup'

export const possibleVendorResponseProvideType = [
  'CAN_PROVIDE_FULLY',
  'CAN_PROVIDE_PARTIALLY',
  'CAN_PROVIDE_ALTERNATIVE',
  'CANNOT_PROVIDE',
  'WAITING_VRF',
] as const

export type VendorResponseProvideType = (typeof possibleVendorResponseProvideType)[number]

export const vendorResponseProvideTypeSchema = yup
  .mixed<VendorResponseProvideType>()
  .oneOf(possibleVendorResponseProvideType)
