import * as yup from 'yup'

import { orderAlternativeProductsModelSchema } from '../order/order-alternative-product.schemas'
import { orderProductAdditionalServiceSchema } from '../order/order-product-additional-service.schemas'
import { vendorModelSchema } from '../vendor'

export const purchaseOrderVendorProductDetailsModelSchema = yup.object({
  id: yup.string().defined(),
  additionalServices: yup.array(orderProductAdditionalServiceSchema).defined(),
  alternativeOrderProducts: orderAlternativeProductsModelSchema.defined(),
  condition: yup.string().defined().nullable(),
  packageIndex: yup.number().defined(),
  quantity: yup.number().defined(),
  pricePerUnitWithoutMargin: yup.number().defined(),
  vendor: vendorModelSchema.defined().nullable(),
})

export const purchaseOrderVendorProductsDetailsModelSchema = yup
  .array(purchaseOrderVendorProductDetailsModelSchema)
  .defined()

export type PurchaseOrderVendorProductDetailsProductModel = yup.InferType<
  typeof purchaseOrderVendorProductDetailsModelSchema
>

export const additionalServiceMutationSchema = yup.object({
  productAdditionalServiceId: yup.string().defined(),
  price: yup.number().defined(),
})

export const alternativeProductMutationSchema = yup.object({
  productId: yup.string().defined(),
  price: yup.number().defined(),
  powerSupplyType: yup.string().nullable(),
  keyboardLanguage: yup.string().nullable(),
  condition: yup.string().defined().nullable(),
})

export const purchaseOrderVendorProductDetailsMutationSchema = yup.object({
  orderProductId: yup.string().defined(),
  commercialOfferId: yup.string().defined(),
  vendorId: yup.string().defined().nullable(),
  quantity: yup.number().min(1).defined(),
  price: yup.number().defined(),
  condition: yup.string().defined().nullable(),
  packageIndex: yup.number().required().min(1).defined(),
  additionalServices: yup.array().of(additionalServiceMutationSchema).defined(),
  alternativeProducts: yup.array().of(alternativeProductMutationSchema).defined(),
})

export type PurchaseOrderVendorProductDetailsMutation = yup.InferType<
  typeof purchaseOrderVendorProductDetailsMutationSchema
>
