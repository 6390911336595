import { useCallback, useEffect, useRef } from 'react'

export const useDebounceCallback = <T extends Array<unknown>>(
  callback: (...args: T) => void,
  delay = 500,
) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const cleanupTimeout = useCallback(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const debouncedCallback = useCallback(
    (...args: T) => {
      cleanupTimeout()
      timeoutRef.current = setTimeout(() => {
        callback(...args)
      }, delay)
    },
    [callback, cleanupTimeout, delay],
  )

  useEffect(() => cleanupTimeout, [cleanupTimeout])

  return debouncedCallback
}
