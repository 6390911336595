import { currencyModelSchema } from '@share/schemas/currency'
import * as yup from 'yup'

import { assetConditionStatesTypeSchema } from '../asset/asset-condition-state.schemas'

import { vendorResponseProvideTypeSchema } from './rfq-vendor-response-provide-types.schema'

export const RFQVendorResponseSchema = yup.object({
  alternativeId: yup.string().nullable(),
  condition: assetConditionStatesTypeSchema.nullable(),
  eta: yup
    .object({
      from: yup.string().defined(),
      to: yup.string().defined(),
    })
    .default(undefined)
    .nullable(),
  formId: yup.string().defined(),
  id: yup.string().nullable(),
  keyboardLayout: yup.string().nullable(),
  mpn: yup.string().nullable(),
  notes: yup.string().nullable(),
  powerLayout: yup.string().nullable(),
  prices: yup
    .object({
      currency: currencyModelSchema.omit(['code']).defined(),
      total: yup.number().defined().nullable(),
      totalInUsd: yup.number().defined().nullable(),
    })
    .default(undefined)
    .nullable(),
  provideType: vendorResponseProvideTypeSchema.defined(),
  quantity: yup.number().nullable(),
  vendorId: yup.string().defined(),
  warranty: yup
    .object({
      id: yup.string().defined(),
      name: yup.string().defined(),
    })
    .default(undefined)
    .nullable(),
})

export type RFQVendorResponseModel = yup.InferType<typeof RFQVendorResponseSchema>
