import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import {
  httpClientIntegration,
  init,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react'
import { APP_VERSION } from '@share/constants'

import { SENTRY_DSN, SENTRY_ENV } from './config'

if (process.env.NODE_ENV === 'production') {
  init({
    dsn: SENTRY_DSN,
    integrations: [
      httpClientIntegration(),
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    release: APP_VERSION,
    environment: SENTRY_ENV,
  })
}
