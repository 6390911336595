import { ApiError } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import { superAdminSchema } from '@share/schemas'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import * as yup from 'yup'

const responseSchema = yup.array().of(superAdminSchema).defined()

export type GetDictionarySuperAdminsResponse = yup.InferType<typeof responseSchema>
type GetDictionarySuperAdminsRequestOptions = UseQueryOptions<
  GetDictionarySuperAdminsResponse,
  ApiError
>

const getDictionarySuperAdminsRequest = async () =>
  apiRequest<GetDictionarySuperAdminsResponse>({
    method: 'GET',
    url: API_LIST.companyAdmin.superAdmins,
    responseSchema,
  })

export const useGetDictionarySuperAdminsRequest = (
  options?: GetDictionarySuperAdminsRequestOptions,
) =>
  useQuery<GetDictionarySuperAdminsResponse, ApiError>({
    queryKey: [API_TAGS.companyAdmin.superAdmins],
    queryFn: getDictionarySuperAdminsRequest,
    ...options,
  })
