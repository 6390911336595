import * as yup from 'yup'

export const companyCreditLogSchema = yup.object({
  id: yup.string().defined(),
  createdAt: yup.string().defined(),
  updatedAt: yup.string().defined(),
  amount: yup.number().defined(),
  type: yup.string().defined(),
  message: yup.string().defined(),
})

export const companyCreditsLogsSchema = yup.object({
  result: yup.array().of(companyCreditLogSchema).defined(),
})

export type CompanyCreditsLogsModel = yup.InferType<typeof companyCreditsLogsSchema>
