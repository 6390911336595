import * as yup from 'yup'

const possibleRFQStatuses = [
  'FULLY',
  'PARTIALLY',
  'ALTERNATIVE',
  'NOT_PROVIDE',
  'PARTIALLY_ALTERNATIVE',
  'WAITING',
] as const

export type RFQStatuses = (typeof possibleRFQStatuses)[number]

export const RFQStatusesSchema = yup.mixed<RFQStatuses>().oneOf(possibleRFQStatuses)
