import * as yup from 'yup'

import { regionModelSchema } from '../country'
import { orderSubtypeSchema, orderTypeSchema, tableOrderStatusSchema } from '../order'

export const lateOrderModelSchema = yup
  .object()
  .shape({
    id: yup.string().defined(),
    region: regionModelSchema.defined(),
    orderType: orderTypeSchema.defined(),
    orderSubType: orderSubtypeSchema.defined(),
    orderStatus: tableOrderStatusSchema.defined(),
    createdBy: yup.string().defined(),
    slaInHours: yup.number().defined(),
  })
  .defined()

export const lateOrdersModelSchema = yup.array().of(lateOrderModelSchema).required()

export type LateOrderModel = yup.InferType<typeof lateOrderModelSchema>
export type LateOrdersModel = yup.InferType<typeof lateOrdersModelSchema>
