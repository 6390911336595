import { Icon, IconProps } from '@share/components/ui/atoms'
import cn from 'classnames'

import s from './confirm.module.scss'

export type ConfirmCircleProps = {
  className?: string
  icon: IconProps['icon']
  iconIndicator?: IconProps['icon']
  size?: IconProps['size']
  color?: 'default' | 'peach' | 'forestGreen'
}

export const ConfirmCircle: React.FC<ConfirmCircleProps> = ({
  icon,
  className,
  iconIndicator,
  size = 'large',
  color = 'default',
}) => (
  <div className={cn(s.circle, { [s[color]]: color }, className)}>
    <Icon size={size} icon={icon} />
    {iconIndicator && <Icon size='medium' icon={iconIndicator} className={s.iconIndicator} />}
  </div>
)
