import { PaginatedResponse, PaginationState, SortState } from '@share/@types'
import { API_LIST, API_TAGS } from '@share/constants'
import { apiRequest } from '@share/helpers'
import {
  CountryTableModel,
  countryTableModelSchema,
  paginationResponseSchema,
} from '@share/schemas'
import { useQuery } from '@tanstack/react-query'

export interface GetCountriesRequestParams extends Partial<PaginationState>, SortState {
  name?: string
}

export type GetCountriesResponse = PaginatedResponse<CountryTableModel>

const responseSchema = paginationResponseSchema(countryTableModelSchema)

const getCountriesRequest = async (params?: GetCountriesRequestParams) =>
  apiRequest<GetCountriesResponse>({
    method: 'GET',
    url: API_LIST.countries.root,
    params,
    responseSchema,
  })

export const useGetCountriesRequest = (params?: GetCountriesRequestParams) =>
  useQuery({
    queryKey: [API_TAGS.countries, params],
    queryFn: () => getCountriesRequest(params),
  })
