import { components, OptionProps } from 'react-select'
import { Tooltip } from '@dots/uikit'

import { SelectOption } from '../types'

export const Option: React.FC<OptionProps<SelectOption>> = (props) => (
  <components.Option {...props}>
    <Tooltip
      side='right'
      title={props.data.tooltip?.title}
      content={props.data.tooltip?.content}
      sideOffset={10}
      trigger={props.label}
    />
  </components.Option>
)
