import * as yup from 'yup'

import { regionModelSchema } from './region.schemas'
import { countrySourcesSchema } from './sources.schemas'

export const countryTableModelSchema = yup.object({
  activeOffices: yup.number().defined(),
  activeStaff: yup.number().defined(),
  activeWarehouses: yup.number().defined(),
  alphaCode2: yup.string().defined().nullable(),
  delaysConfig: yup
    .object({
      deliveryDelayFrom: yup.number().defined(),
      deliveryDelayTo: yup.number().defined(),
      fastDeliveryDelayFrom: yup.number().defined(),
      fastDeliveryDelayTo: yup.number().defined(),
      purchaseDeliveryDelayFrom: yup.number().defined(),
      purchaseDeliveryDelayTo: yup.number().defined(),
    })
    .defined(),
  deliveryDelayFrom: yup.number().defined(),
  deliveryDelayTo: yup.number().defined(),
  flag: yup.string().defined().nullable(),
  id: yup.string().defined(),
  minDeliveryDate: yup.string().defined(),
  name: yup.string().defined(),
  region: regionModelSchema.defined().nullable(),
  regionId: yup.string().defined().nullable(),
  source: countrySourcesSchema.optional(),
})

export const countriesTableModelSchema = yup.array().of(countryTableModelSchema).defined()

export type CountryTableModel = yup.InferType<typeof countryTableModelSchema>
export type CountriesTableModel = yup.InferType<typeof countriesTableModelSchema>

export const countryV2ModelSchema = yup.object({
  activeOffices: yup.number().defined(),
  activeStaff: yup.number().defined(),
  activeWarehouses: yup.number().defined(),
  alphaCode2: yup.string().defined().nullable(),
  delaysConfig: yup
    .object({
      deliveryDelayFrom: yup.number().defined(),
      deliveryDelayTo: yup.number().defined(),
      fastDeliveryDelayFrom: yup.number().defined(),
      fastDeliveryDelayTo: yup.number().defined(),
      purchaseDeliveryDelayFrom: yup.number().defined(),
      purchaseDeliveryDelayTo: yup.number().defined(),
    })
    .defined(),
  deliveryDelayFrom: yup.number().defined(),
  deliveryDelayTo: yup.number().defined(),
  flag: yup.string().defined().nullable(),
  id: yup.string().defined(),
  minDeliveryDate: yup.string().defined(),
  name: yup.string().defined(),
  regionId: yup.string().defined().nullable(),
  source: countrySourcesSchema.optional(),
})

export const countriesV2ModelSchema = yup.array().of(countryV2ModelSchema).defined()

export type CountryV2Model = yup.InferType<typeof countryV2ModelSchema>
export type CountriesV2TableModel = yup.InferType<typeof countryV2ModelSchema>
