import { Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { LoadingOverlay, TooltipProvider as TooltipProviderKit } from '@dots/uikit'
import { Provider as ModalsProvider } from '@ebay/nice-modal-react'
import { TooltipProvider } from '@radix-ui/react-tooltip'
import { ErrorBoundary, HintContainer } from '@share/components/layouts'
import { queryClient } from '@share/helpers'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

export const withProviders = (WrappedComponent: React.FC) => {
  const ComponentWithProviders = () => (
    <HelmetProvider>
      <ErrorBoundary>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <Suspense fallback={<LoadingOverlay variant='naked' />}>
              <TooltipProvider>
                <TooltipProviderKit>
                  <ModalsProvider>
                    <WrappedComponent />
                  </ModalsProvider>
                </TooltipProviderKit>
              </TooltipProvider>
            </Suspense>

            <HintContainer />

            <ReactQueryDevtools position='bottom-left' />
          </QueryClientProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </HelmetProvider>
  )

  ComponentWithProviders.displayName = 'withProviders'

  return ComponentWithProviders
}
