import { mixed } from 'yup'

export const possibleOrderActionIndicatorType = [
  'HAS_UPDATES',
  'ACTION_REQUIRES',
  'AT_RISK',
] as const

export type OrderActionIndicatorType = (typeof possibleOrderActionIndicatorType)[number]

export const possibleOrderActionIndicatorTypeSchema = mixed<OrderActionIndicatorType>()
  .defined()
  .oneOf(possibleOrderActionIndicatorType)
