import * as yup from 'yup'

const possiblePaymentInformationTypes = ['WIRE_TRANSFER', 'CREDITS'] as const
export type PaymentInformationType = (typeof possiblePaymentInformationTypes)[number]

export const paymentInformationModelSchema = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  type: yup.mixed<PaymentInformationType>().oneOf(possiblePaymentInformationTypes).defined(),
})

export type PaymentInformationModel = yup.InferType<typeof paymentInformationModelSchema>
