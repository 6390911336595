import * as yup from 'yup'

export const rfqFilterModelSchema = yup
  .object({
    name: yup.string().defined(),
    outputName: yup.string().defined(),
    searchable: yup.boolean().defined(),
    specDetailId: yup.string().defined().nullable(),
    specDetailValues: yup.array(yup.string().defined()).defined(),
    valueType: yup.mixed<string | number>().defined().nullable(),
    unitOfMeasure: yup.string().defined().nullable(),
  })
  .noUnknown()

export const vrfFiltersModelSchema = yup.array().of(rfqFilterModelSchema.defined()).defined()

export type RFQFilterModel = yup.InferType<typeof rfqFilterModelSchema>
export type RFQFiltersModel = yup.InferType<typeof vrfFiltersModelSchema>
