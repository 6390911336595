import * as yup from 'yup'

import { brandModelSchema } from '../brand'
import { countryModelSchema, regionModelSchema } from '../country'
import { currencyModelSchema } from '../currency'

import { vendorContactInformationModelSchema } from './vendor-contact-information.schemas'
import { vendorServicesModelSchema } from './vendor-services.schemas'

export const vendorModelSchema = yup.object({
  alreadyHasVrf: yup.boolean().defined(),
  brands: yup.array().of(brandModelSchema).defined(),
  companyName: yup.string().defined(),
  contactInformation: vendorContactInformationModelSchema.defined(),
  countries: yup.array().of(countryModelSchema).defined(),
  currencies: yup.array().of(currencyModelSchema).defined(),
  id: yup.string().defined(),
  mainProducts: yup.string().defined(),
  notes: yup.string().nullable().defined(),
  primaryEntityCountry: countryModelSchema.nullable().defined(),
  regions: yup.array().of(regionModelSchema).defined(),
  services: vendorServicesModelSchema.defined(),
  vendorDepId: yup.string().nullable().defined(),
})

export const vendorsModelSchema = yup.array().of(vendorModelSchema).defined()

export const vendorMutationRequestSchema = yup.object({
  companyName: yup.string().defined(),
  vendorDepId: yup.string().defined(),
  primaryEntityCountryId: yup.string().defined(),
  contactInformation: vendorContactInformationModelSchema.defined(),
  services: vendorServicesModelSchema.defined(),
  mainProducts: yup.string().defined(),
  countries: yup.array().of(yup.string()).defined(),
  regions: yup.array().of(yup.string()).defined(),
  currencies: yup.array().of(yup.string()).defined(),
  brands: yup.array().of(yup.string()).defined(),
  notes: yup.string().nullable().defined(),
})

export type VendorModel = yup.InferType<typeof vendorModelSchema>
export type VendorsModel = yup.InferType<typeof vendorsModelSchema>

export type VendorMutationRequest = yup.InferType<typeof vendorMutationRequestSchema>
